import React from 'react'

function ContactUs() {
  return (
    <div className='bg-green-700 pt-4 rounded-tl-xl rounded-tr-xl h-96'>
        
        <h2 className='text-xl font-bold text-white border-b-2 pb-4'>Any Query</h2>

    </div>
  )
}

export default ContactUs 